// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/menu-sidebar-white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".component-fade-enter-active,.component-fade-leave-active{transition:opacity .3s ease}.component-fade-enter,.component-fade-leave-to{opacity:0}#viewer.modal-mask--dark .action-item--single.icon-menu-sidebar{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}#viewer.modal-mask--dark .action-item--single.icon-download{background-image:var(--icon-download-fff)}.ui-autocomplete{z-index:2050 !important}", "",{"version":3,"sources":["webpack://./src/views/Viewer.vue"],"names":[],"mappings":"AACA,0DAEC,2BAAA,CAGD,+CACC,SAAA,CAID,gEACC,wDAAA,CAGD,4DACC,yCAAA,CAMD,iBACC,uBAAA","sourcesContent":["\n.component-fade-enter-active,\n.component-fade-leave-active {\n\ttransition: opacity .3s ease;\n}\n\n.component-fade-enter, .component-fade-leave-to {\n\topacity: 0;\n}\n\n// force white icon on single buttons\n#viewer.modal-mask--dark .action-item--single.icon-menu-sidebar {\n\tbackground-image: url('../assets/menu-sidebar-white.svg');\n}\n\n#viewer.modal-mask--dark .action-item--single.icon-download {\n\tbackground-image: var(--icon-download-fff);\n}\n\n// put autocomplete over full sidebar\n// TODO: remove when new sharing sidebar (18)\n// is the min-version of viewer\n.ui-autocomplete {\n\tz-index: 2050 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
