var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.editing)?_c('ImageEditor',{attrs:{"mime":_vm.mime,"src":_vm.src,"fileid":_vm.fileid},on:{"close":_vm.onClose}}):(_vm.data !== null)?_c('img',{class:{
		dragging: _vm.dragging,
		loaded: _vm.loaded,
		zoomed: _vm.zoomRatio !== 1
	},style:({
		marginTop: _vm.shiftY + 'px',
		marginLeft: _vm.shiftX + 'px',
		maxHeight: _vm.zoomRatio * 100 + '%',
		maxWidth: _vm.zoomRatio * 100 + '%',
	}),attrs:{"alt":_vm.alt,"src":_vm.data},on:{"~!error":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onFail.apply(null, arguments)},"load":_vm.updateImgSize,"wheel":_vm.updateZoom,"dblclick":function($event){$event.preventDefault();return _vm.onDblclick.apply(null, arguments)},"mousedown":function($event){$event.preventDefault();return _vm.dragStart.apply(null, arguments)}}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }